
import { defineComponent, ref, toRef } from "vue"
import Swal from "sweetalert2/dist/sweetalert2.js"
import { hideModal } from "@/core/helpers/dom"
import * as Yup from "yup"

import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import type { ElForm } from "element-plus"

type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  name: "new-point-modal",
  components: {},
  props: [
    'member_id',
    'add_point'
  ],
  setup(props) {
    const formRef = ref<FormInstance>()
    const store = useStore()
    const newPointModalRef = ref<null | HTMLElement>(null)
    const point = ref(1)

    const onSubmit = (formEl: FormInstance | undefined) => {
      props.add_point(props.member_id, point.value)
      point.value = 1
      hideModal(newPointModalRef.value)
    }

    const onCancel = () => {
      hideModal(newPointModalRef.value);
    }

    return {
      newPointModalRef,
      onSubmit,
      formRef,
      onCancel,
      point
    }
  },
})
